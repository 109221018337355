<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="
        (operateTitle = '添加'), (editData = {}), (isAddVisible = true)
      "
      @del-change="deleteItem"
      @export-data="exportData"
      @import-click="importClick"
      tableName="policyManagement"
    >
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box
          :statisticsList="statisticsList"
          v-loading="statisticsLoading"
        />
      </template>
      <template slot="operate-left">
        <el-button
          type="primary"
          size="mini"
          v-if="
            handleHasPerms(
              'M20-allocationCustomer_' + this.$route.meta.policyType
            )
          "
          @click="handleAllocationCustomer"
          >分配客户</el-button
        >
      </template>
      <template slot="totalPremiumBegin">
        <el-input
          v-model.trim="searchData.totalPremiumBegin"
          clearable
          @input="input_num($event, searchData, 'totalPremiumBegin')"
          placeholder="开始金额"
        ></el-input>
        <span>-</span>
        <el-input
          v-model="searchData.totalPremiumEnd"
          clearable
          @input="input_num($event, searchData, 'totalPremiumEnd')"
          placeholder="结束金额"
        ></el-input>
      </template>
      <template slot="statusName" slot-scope="{ item }">
        <div :class="['status', 'status' + item.status]">
          {{ item.statusName }}
        </div>
      </template>
      <template slot="seeFee" slot-scope="{ item }">
        <div :style="{ color: !item.seeFee ? '#fca118' : '#0080ff' }">
          {{ item.seeFee ? "见费" : "非见费" }}
        </div>
      </template>
      <template slot="approvalStatusDesc" slot-scope="{ item }">
        <div
          :class="['approval-status', 'approval-status' + item.approvalStatus]"
        >
          {{ item.approvalStatusDesc }}
        </div>
      </template>
      <template slot="policyNo" slot-scope="{ item }">
        <div
          class="el-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="handleDropdownClick('details', item)"
        >
          {{ item.policyNo }}
        </div>
      </template>
      <template slot="enquiryNo" slot-scope="{ item }">
        <div
          v-if="item.enquiryNo"
          class="el-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="handleGoInquiryPath(item)"
        >
          {{ item.enquiryNo }}
        </div>
        <div v-else>--</div>
      </template>
      <template slot="insuranceTypeName" slot-scope="{ item }">
        {{
          $route.meta.policyType == 1
            ? item.insuranceTypeName
            : item.insuredCategoryName
        }}
      </template>
      <template slot="brandName" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.brandName : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="deviceTypeName"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.deviceTypeName : '多标的类型，详细查看详情' }}
      </template>
      <template slot="frameNo" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.frameNo : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="equipmentType" slot-scope="{ item }">
        {{
          item.isSingle !== false
            ? item.equipmentType
            : "多标的类型，详细查看详情"
        }}
      </template>

      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          type="text"
          @click="handleCreateCorrectionOrder(item)"
          :disabled="
            item.status != '1' && item.status != '2' && item.status != '3'
          "
          v-if="
            handleHasPerms(`M20-correcting_${$route.meta.policyType}`) &&
            (item.approvalStatus == 5 || item.approvalStatus == 3) &&
            loginIdentity !== 'agent'
          "
          size="small"
          >保单批改</el-button
        >
        <!-- item.status != 3  批改续保需求-过期保单续保 -->
        <el-button
          class="polecy-button"
          type="text"
          @click="handleDropdownClick('details', item)"
          v-if="handleHasPerms(`M20-details_${$route.meta.policyType}`)"
          size="small"
          >保单详情</el-button
        >
        <el-button
          class="polecy-button"
          type="text"
          @click="handleRevocation(item)"
          v-if="
            handleHasPerms(`M20-edit_${$route.meta.policyType}`) &&
            item.approvalStatus == 2 &&
            loginIdentity !== 'agent'
          "
          size="small"
          >撤回</el-button
        >
        <el-button
          class="polecy-button"
          type="text"
          @click="handleElectronicPolicy(item)"
          v-if="
            handleDataPermissions(`M20_${$route.meta.policyType}-View`, 'ep') &&
            (item.approvalStatus == 5 || item.approvalStatus == 3)
          "
          size="small"
          >电子保单</el-button
        >
        <el-dropdown @command="handleDropdownClick($event, item)">
          <span class="el-dropdown-link">
            更多
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="reportingHistory"
              v-if="
                handleHasPerms(
                  `M20-reportingHistory_${$route.meta.policyType}`
                ) &&
                (item.approvalStatus == 5 || item.approvalStatus == 3)
              "
              >报案历史</el-dropdown-item
            >
            <el-dropdown-item
              command="claim"
              v-if="
                handleHasPerms(`M20-applyClaim_${$route.meta.policyType}`) &&
                (item.approvalStatus == 5 || item.approvalStatus == 3) &&
                $route.meta.policyType != 3 &&
                loginIdentity !== 'agent'
              "
              >申请理赔</el-dropdown-item
            >
            <el-dropdown-item
              command="correctingH"
              v-if="
                handleHasPerms(`M20-correctingH_${$route.meta.policyType}`) &&
                (item.approvalStatus == 5 || item.approvalStatus == 3)
              "
              >批改历史</el-dropdown-item
            >
            <el-dropdown-item command="seeFee" :disabled="item.seeFee !== false"
              >见费确认</el-dropdown-item
            >
            <el-dropdown-item
              command="edit"
              v-if="
                handleHasPerms(`M20-edit_${$route.meta.policyType}`) &&
                item.approvalStatus != 2 &&
                loginIdentity !== 'agent'
              "
              >编辑</el-dropdown-item
            >
            <el-dropdown-item
              command="del"
              v-if="
                handleHasPerms(`M20-del_${$route.meta.policyType}`) &&
                loginIdentity !== 'agent'
              "
              >删除</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </GeneralTable>
    <policy-import
      v-model="isImport"
      @changeList="
        getList(
          oldSearchData,
          oldSearchData.current,
          oldSearchData.size,
          '',
          'refresh'
        )
      "
    />
    <electronic-policy
      v-model="electronicVisible"
      :id="editData.id"
      @changeList="
        getList(
          oldSearchData,
          oldSearchData.current,
          oldSearchData.size,
          '',
          'refresh'
        )
      "
    />
    <HistoricalRecord ref="hisRd" :hisShow.sync="hisShow"></HistoricalRecord>
    <reportingHistory
      v-model="reportingVisible"
      :formData="reportingFormData"
      :totalPremium="reportingFormData.totalPremium"
    />
    <allocationCustomerDialog
      :ids="customerIds"
      apiType="policy"
      v-model="isAllocationCustomer"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import staffAddDialog from "@/views/SystemManagement/components/staff/staffAddDialog.vue";
import PolicyImport from "@/views/policyManagement/components/policyImport.vue";
import electronicPolicy from "@/views/policyManagement/components/electronicPolicy.vue";
import HistoricalRecord from "@/views/policyManagement/components/HistoricalRecord";
import reportingHistory from "@/views/policyManagement/components/reportingHistory";
import allocationCustomerDialog from "@/views/policyManagement/components/allocationCustomerDialog";
import {
  policyList,
  policyFastPage,
  exportPolicyByIds,
  exportPolicy,
  policyRemove,
  dictionaryBatch,
  policyProcessRevoke,
  getDataStatistics,
  countersignSeeFee,
} from "@/api/policy";
import { pageListToSelect } from "@/api/basicData.js";
import { hasPerms, dataPermissions } from "@/utils/auth";
import dayjs from "dayjs";
export default {
  name: "policyManagement1",
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      historyRecords: [],
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: hasPerms("M20-del_" + this.$route.meta.policyType), //删除 @del-change
        isEdit: hasPerms("M20-edit_" + this.$route.meta.policyType), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isImport:
          hasPerms("M20-import_" + this.$route.meta.policyType) &&
          this.loginIdentity !== "agent", // 导入 @import-click
        isExport: hasPerms("M20-export_" + this.$route.meta.policyType),
        pageSize: 10,
        rowPlaceholder: "--", // 列表数据为空时的显示
        tableHeight: "calc(100vh - 122px)",
        searchList: [
          {
            label: "投保日期",
            prop: "insuranceOfDate",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["insuranceOfDateBegin", "insuranceOfDateEnd"],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "insuranceOfDate"
            ),
          },
          {
            label: "保单号",
            prop: "policyNo",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入保单号",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "policyNo"
            ),
          },
          {
            label: "询价单号",
            prop: "enquiryNo",
            formType: "input",
            maxLength: 80,
            clearable: true,
            placeholder: "请输入询价单号",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "enquiryNo"
            ),
          },
          {
            label: "投保人",
            prop: "policyHolder",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入投保人",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "policyHolder"
            ),
          },
          {
            label: "被保险人",
            prop: "insured",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入被保险人",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "insured"
            ),
          },
          {
            label: "险种名称",
            prop: "insuranceTypeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入险种名称",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "insuranceTypeName"
              ) && this.$route.meta.policyType == 1,
          },
          {
            label: "险种类型",
            prop: "insuredCategoryName",
            formType: "select",
            filterable: true,
            selectList: [],
            clearable: true,
            placeholder: "请选择",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "insuranceTypeName"
              ) && this.$route.meta.policyType != 1,
          },
          {
            label: "起保日期",
            prop: "startDateBegin",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["startDateBegin", "startDateEnd"],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "startDate"
            ),
          },
          {
            label: "保险到期",
            prop: "endDate",
            formType: "daterange",
            format: "yyyy.MM.dd",
            clearable: true,
            prop: ["endDateBegin", "endDateEnd"],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "endDate"
            ),
          },
          {
            label: "保险公司",
            prop: "insuranceCompanyName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
            // formType: "select",
            // filterable: true,branchName
            // selectList: [
            //   { value: "", text: "全部" },
            //   { value: "1", text: "平安保险" },
            //   { value: "2", text: "人寿保险" },
            //   { value: "3", text: "人地保险" },
            //   { value: "4", text: "中国平安保险（集团）股份有限公司" },
            // ],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "insuranceCompany"
            ),
          },
          {
            label: "分支机构",
            prop: "branchName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "insuranceBranchName"
            ),
          },
          {
            label: "车架号",
            prop: "frameNo",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入车架号",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "frameNo"
              ) && this.$route.meta.policyType != 3,
          },
          {
            label: "设备类型",
            prop: "deviceTypeName",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
            isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "frameNo") && this.$route.meta.policyType != 3,
          },
          {
            label: "车牌号",
            prop: "plate",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "plate"
              ) && this.$route.meta.policyType == 2,
          },
          {
            label: "总保费",
            prop: "totalPremiumBegin",
            formType: "range-input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入总保费",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "totalPremium"
              ) && false,
          },
          {
            label: "保单状态",
            prop: "status",
            formType: "select",
            filterable: true,
            placeholder: "请选择保单状态",
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "未起保" },
              { value: "2", text: "保障中" },
              { value: "3", text: "已过期" },
            ],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "state"
            ),
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入业务员",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "salesmanName"
            ),
          },
          {
            label: "所属客户",
            prop: "bindUserName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "bindUser"
            ),
          },
          {
            label: "项目名称",
            prop: "projectNameList",
            formType: "cascader",
            formProps: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: "projectName",
              label: "projectName",
              children: "subProjectList",
            },
            collapseTags: true,
            showAllLevels: false,
            clearable: true,
            // filterable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "projectName"
            ),
            //  && this.loginIdentity === 'agent'
          },
          {
            label: "业务渠道",
            prop: "sourceChannelVal",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "sourceChannel"
            ),
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions:
              dataPermissions(
                `M20_${this.$route.meta.policyType}-View`,
                "deptName"
              ) && this.loginIdentity !== "agent",
          },
          {
            label: "保单来源",
            prop: "fromSourceType",
            formType: "select",
            filterable: true,
            placeholder: "请选择保单来源",
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "转保单" },
              { value: "2", text: "一立云小程序" },
              { value: "3", text: "批量导入" },
            ],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "fromSourceType"
            ),
          },
          {
            label: "审核状态",
            prop: "processStatus",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: "5", text: "系统通过" },
              { value: "3", text: "人工通过" },
              { value: "2", text: "审核中" },
              { value: "1", text: "审核拒绝" },
              { value: "4", text: "已撤回" },
            ],
          },
          {
            label: "录单员",
            prop: "recorderName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入录单员",
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "recorderName"
            ),
          },
          {
            label: "业务代理人",
            prop: "upstreamAgentName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务代理人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "upstreamAgentName"
            ),
          },
          // {
          //   label: "签单时间",
          //   formType: "daterange",
          //   clearable: true,
          //   format: "yyyy.MM.dd",
          //   prop: ["signBillsDateBegin", "signBillsDateEnd"],
          //   // propValue: [dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')],
          //   isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "signBillsDate"),
          // },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["createTimeBegin", "createTimeEnd"],
            // propValue: [dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')],  //2023-8-18 默认创建日期的话想改回不然每次直接搜索都需再按一遍取消创建日期
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "createTime"
            ),
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "保单号",
          prop: "policyNo",
          align: "center",
          width: 280,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "policyNo"
          ),
        },
        {
          label: "询价单号",
          prop: "enquiryNo",
          align: "center",
          width: 280,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "enquiryNo"
          ),
        },
        {
          label: "投保人",
          prop: "policyHolder",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "policyHolder"
          ),
          width: 220,
        },
        {
          label: "被保险人",
          prop: "insured",
          align: "center",
          width: 220,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "insured"
          ),
        },
        {
          label: this.$route.meta.policyType == 1 ? "险种名称" : "险种类型",
          prop: "insuranceTypeName",
          align: "center",
          isShow: true,
          isSlot: true,
          width: 265,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "insuranceTypeName"
          ),
        },
        {
          label: "保险公司",
          prop: "insuranceCompanyName",
          align: "center",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "insuranceCompany"
          ),
        },
        {
          label: "分支机构",
          prop: "insuranceBranchName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insuranceBranchName"
          ),
        },
        {
          label: "设备类型",
          prop: "deviceTypeName",
          align: "center",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "brand") && this.$route.meta.policyType != 3,
        },
        {
          label: "设备品牌",
          prop: "brandName",
          align: "center",
          width: 200,
          isSlot: true,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "brand"
          ),
        },
        {
          label: "设备型号",
          prop: "equipmentType",
          align: "center",
          width: 200,
          isSlot: true,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "equipmentType"
          ),
        },
        {
          label: "车架号",
          prop: "frameNo",
          align: "center",
          width: 200,
          isSlot: true,
          isShow: true,
          isPermissions:
            dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "frameNo"
            ) && this.$route.meta.policyType != 3,
        },
        {
          label: "车牌号",
          prop: "plate",
          align: "center",
          width: 200,
          isShow: true,
          isPermissions:
            dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "plate"
            ) && this.$route.meta.policyType == 2,
        },
        {
          label: "投保日期",
          prop: "insuranceOfDate",
          align: "center",
          width: 120,
          isShow: true,
          width: 100,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "insuranceOfDate"
          ),
        },
        {
          label: "起保日期",
          prop: "startDate",
          align: "center",
          width: 120,
          isShow: true,
          width: 100,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "startDate"
          ),
        },
        {
          label: "保险到期",
          prop: "endDate",
          align: "center",
          width: 120,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "endDate"
          ),
        },
        {
          label: "总保费（元）",
          prop: "totalPremium",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "totalPremium"
          ),
        },
        {
          label: "保单状态",
          prop: "statusName",
          align: "center",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "policyState"
          ),
        },
        {
          label: "出单类型",
          prop: "seeFee",
          align: "center",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "policyState"
          ),
        },
        {
          label: "所属客户",
          prop: "bindUserName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "bindUser"
          ),
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "salesmanDeptName"
          ),
        },
        {
          label: "项目名称",
          prop: "projectName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "projectName"
          ),
        },
        {
          label: "业务渠道",
          prop: "sourceChannel",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "sourceChannel"
          ),
        },
        {
          label: "业务员",
          prop: "salesmanName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "salesmanName"
          ),
        },
        {
          label: "代理人",
          prop: "agentName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "agentName"
          ),
        },
        {
          label: "业务代理人",
          prop: "upstreamAgentName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "upstreamAgentName"
          ),
        },
        {
          label: "保单来源",
          prop: "fromSourceTypeName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "fromSourceType"
          ),
        },
        {
          label: "审核状态",
          prop: "approvalStatusDesc",
          align: "center",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "approvalStatus"
          ),
        },
        {
          label: "录单员",
          prop: "recorderName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "recorder"
          ),
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "createTime"
          ),
        },
        // {
        //   label: "签单时间",
        //   prop: "signBillsDate",
        //   width: 200,
        //   align: "center",
        //   isShow: true,
        //   isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "signBillsDate"),
        // },
        {
          label: "更新时间",
          prop: "updateTime",
          align: "center",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M20_${this.$route.meta.policyType}-View`,
            "updateTime"
          ),
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: "center",
          width: 330,
          fixed: "right",
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: "添加",
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: "",
      passwordType: "text",
      searchData: {
        totalPremiumBegin: "",
        totalPremiumEnd: "",
      },
      oldSearchData: {
        totalPremiumBegin: "",
        totalPremiumEnd: "",
      },
      isImport: false,
      electronicVisible: false,
      reportingVisible: false,
      hisShow: false,
      reportingFormData: {},
      isAllocationCustomer: false,
      customerIds: [],
      statisticsList: [
        {
          name: "保单总数",
          value: 0,
        },
        {
          name: "保单总保费",
          value: 0,
        },
        {
          name: "批改总保费",
          value: 0,
        },
        {
          name: "合计总保费",
          value: 0,
        },
        {
          name: "报案次数",
          value: 0,
        },
        {
          name: "赔付总金额",
          value: 0,
        },
        {
          name: "满期赔付率",
          value: 0,
          unit: "%",
        },
      ],
      statisticsLoading: false,
      loginIdentity: "",
    };
  },
  components: {
    GeneralTable,
    staffAddDialog,
    PolicyImport,
    electronicPolicy,
    HistoricalRecord,
    reportingHistory,
    allocationCustomerDialog,
    StatisticsBox,
  },
  created() {
    this.loginIdentity = this.userInfo.loginIdentity;
    if (this.userInfo.loginIdentity == "agent") {
      this.handleColumn("所属部门");
      this.handleColumn("项目名称");
      if (this.$route.meta.policyType === 1) this.handleColumn("代理人");
      this.$forceUpdate();
    }
    if (this.$route.meta.policyType == 3) {
      let ind = this.column.findIndex((val) => val.label === "分支机构");
      let ind2 = this.column.findIndex((val) => val.label === "投保日期");
      this.column.splice(ind + 1, ind2 - ind - 1);
      this.$forceUpdate();
    }
    dictionaryBatch({
      codes: "insuranceCompany,FCXInsCategory,CXInsCategory,projectName,CXInsProjectName,FCXInsProjectName,CXchannel,vehicleType,CXvehicleType",
    }).then((res) => {
      if (res && res.data) {
        let ind = this.option.searchList.findIndex(
          (val) => val.label === "保险公司"
        );
        this.option.searchList[ind].selectList = res.data.insuranceCompany.map(
          (val) => {
            return {
              text: val.dictValue,
              value: val.dictKey,
            };
          }
        );
        this.option.searchList[ind].selectList.unshift({
          text: "全部",
          value: "",
        });
        if (this.$route.meta.policyType != 3) {
          let indv = this.option.searchList.findIndex(
            (val) => val.label === "设备类型"
          );
          this.option.searchList[indv].selectList = this.$route.meta.policyType == 2 ? res.data.CXvehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          ) : res.data.vehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          );
          this.option.searchList[indv].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
        if (this.$route.meta.policyType != 1) {
          let ind3 = this.option.searchList.findIndex(
            (val) => val.label === "险种类型"
          );
          this.option.searchList[ind3].selectList =
            this.$route.meta.policyType == 2
              ? res.data.CXInsCategory.map((val) => {
                  return {
                    text: val.dictValue,
                    value: val.dictValue,
                  };
                })
              : res.data.FCXInsCategory.map((val) => {
                  return {
                    text: val.dictValue,
                    value: val.dictValue,
                  };
                });
          this.option.searchList[ind3].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
        let index10 = this.option.searchList.findIndex(
          (val) => val.label === "业务渠道"
        );
        this.option.searchList[index10].selectList = res.data.CXchannel.map(
          (val) => {
            return {
              text: val.dictValue,
              value: val.dictValue,
            };
          }
        );
      }
    });

    pageListToSelect({
      projectName: "",
      bladeUserId: this.$store.state.userInfo.MJUserId,
    }).then((res) => {
      let ind2 = this.option.searchList.findIndex(
        (val) => val.label === "项目名称"
      );
      this.option.searchList[ind2].selectList = res.data;
    });
  },
  watch: {},
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      let currentData = {
        totalPremiumBegin: "",
        totalPremiumEnd: "",
        current: pageNum,
        size: pageSize,
        condition: v.keyword,
        category: this.$route.meta.policyType,
        orderType: 2,
      };
      v.frameNo = v.frameNo || v.frameNoLike || "";
      if (v.projectNameList && v.projectNameList.length) {
        v.projectName = v.projectNameList.toString();
        delete v.projectNameList;
      }
      if (v.createTimeBegin) {
        v.createTimeBegin =
          dayjs(v.createTimeBegin).format("YYYY-MM-DD") + " 00:00:00";
        v.createTimeEnd =
          dayjs(v.createTimeEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (v.signBillsDateBegin) {
        v.signBillsDateBegin =
          dayjs(v.signBillsDateBegin).format("YYYY-MM-DD") + " 00:00:00";
        v.signBillsDateEnd =
          dayjs(v.signBillsDateEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (v.insuranceOfDateBegin) {
        v.insuranceOfDateBegin =
          dayjs(v.insuranceOfDateBegin).format("YYYY-MM-DD") + " 00:00:00";
        v.insuranceOfDateEnd =
          dayjs(v.insuranceOfDateEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (isReset == "reset") {
        this.searchData.totalPremiumBegin = "";
        this.searchData.totalPremiumEnd = "";
        this.oldSearchData.totalPremiumBegin = "";
        this.oldSearchData.totalPremiumEnd = "";
      } else if (isReset == "refresh") {
        currentData = JSON.parse(JSON.stringify(this.oldSearchData));
      } else {
        this.oldSearchData = JSON.parse(
          JSON.stringify({ ...currentData, ...v })
        );
        currentData = JSON.parse(JSON.stringify(this.searchData));
      }
      this.statisticsLoading = true;
      getDataStatistics({
        ...v,
        ...currentData,
        current: pageNum,
        size: pageSize,
        condition: v.keyword,
        category: this.$route.meta.policyType,
        orderType: 2,
      })
        .then((res) => {
          this.statisticsLoading = false;
          this.statisticsList[0].value = res.data.total || 0;
          this.statisticsList[1].value = res.data.policyTotalPremium || 0;
          this.statisticsList[2].value = res.data.riderTotalPremium || 0;
          this.statisticsList[3].value = res.data.totalPremium || 0;
          this.statisticsList[4].value = res.data.caseTime || 0;
          this.statisticsList[5].value = res.data.realCompensationAmount || 0;
          this.statisticsList[6].value = res.data.compensateRate || 0;
        })
        .catch((err) => {
          this.statisticsLoading = false;
        });
      const { code, data } = await policyList({
        ...v,
        ...currentData,
        current: pageNum,
        size: pageSize,
        condition: v.keyword,
        category: this.$route.meta.policyType,
        orderType: 2,
      });
      if (code === 200 && data.records && data.records.length) {
        this.claimRecordList = data.records.map((val) => {
          val.roleId ? (val.roleId = val.roleId.split(",")) : "";
          // val.deptId = val.deptId.split(',');
          return val;
        });
        this.totalNum = data.total;
      } else {
        this.totalNum = 0;
        this.claimRecordList = [];
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    handleColumn(label) {
      let ind = this.column.findIndex((val) => val.label === label);
      this.column.splice(ind, 1);
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm("删除后数据不能恢复是否确认要删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let postData = new FormData();
            let ids = e.map((val) => val.id).toString();
            postData.append("ids", ids);
            policyRemove(postData)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.changeList();
              })
              .catch((err) => {
                this.$message({
                  type: "error",
                  message: "失败!",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的保单！");
      }
    },
    // 编辑
    editItem(e) {
      e.password = "123456";
      this.editData = e;
      this.operateTitle = "编辑员工-" + e.name;
      this.isAddVisible = true;
    },
    handleCreateCorrectionOrder(e) {
      this.$router.push({
        path:
          `/routerPolicyManagement/${this.$route.meta.policyRoute}/createCorrectionOrder/` +
          e.id,
      });
    },
    handleUpdateUser() {
      updateUser({
        ...this.editData,
        roleId:
          this.editData.roleId && this.editData.roleId.length
            ? this.editData.roleId.toString()
            : "",
        tenantId:
          this.$store.state.tenantId === "000000"
            ? this.editData.tenantId
            : this.$store.state.tenantId,
        yiLiUserToken: this.$store.state.appToken,
        nowUserId: this.$store.state.userInfo.MJUserId,
      })
        .then((res) => {
          if (res) {
            this.isByUserVisible = false;
            this.$message.success("修改成功");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败！");
        });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning("请选择需要导出的保单！");
      //   return false;
      // }
      exportPolicy({
        ...this.oldSearchData,
        category: this.$route.meta.policyType,
        ids: e.map((val) => val.id).toString(),
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDataPermissions(key, perms) {
      return dataPermissions(key, perms);
    },
    handleForbidden() {
      if (
        this.$refs.GeneralTable.tableSelectionList &&
        this.$refs.GeneralTable.tableSelectionList.length
      ) {
        let ids = this.$refs.GeneralTable.tableSelectionList
          .map((val) => val.id)
          .toString();
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        userEnable({
          ids,
          enable: 0,
        })
          .then((res) => {
            loading.close();
            this.$message.success(res.msg || "操作成功");
            this.changeList();
          })
          .catch((err) => {
            loading.close();
            console.log(err);
          });
      } else {
        this.$message.warning("请选择需要禁用的员工！");
        return false;
      }
    },
    handleDropdownClick(e, item) {
      if (e == "edit") {
        this.$router.push({
          path:
            `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyEdit/` +
            item.id,
        });
      } else if (e == "details") {
        this.$router.push({
          path:
            `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyDetails/` +
            item.id,
        });
      } else if (e == "del") {
        this.deleteItem([item]);
      } else if (e == "claim") {
        this.$router.push({
          path: `/routerClaimManagement/applyClaim?policyId=${
            item.id
          }&category=${this.$route.meta.policyType}&orderType=${2}`,
        });
      } else if (e == "reportingHistory") {
        this.reportingFormData = {
          policyNo: item.policyNo,
          policyId: item.id,
          totalPremium: item.totalPremium,
        };
        this.reportingVisible = true;
      } else if (e == "correctingH") {
        this.getCorrectionHistory(item);
      } else if (e == "seeFee") {
        this.$confirm("是否确认保单见费？", "见费确认", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            countersignSeeFee({
              policyId: item.id,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "见费成功!",
                });
              })
              .catch((err) => {
                this.$message({
                  type: "error",
                  message: "见费失败!",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消见费",
            });
          });
      }
    },
    importClick() {
      this.isImport = true;
    },
    handleElectronicPolicy(e) {
      this.editData = e;
      this.electronicVisible = true;
    },
    //获取批改历史
    getCorrectionHistory(item) {
      this.$refs.hisRd.getDataList(item);
      this.hisShow = true;
    },
    handleGoInquiryPath(e) {
      this.$router.push({
        path:
          `/routerPolicyManagement/${this.$route.meta.policyRoute}/enquiryDetails/` +
          e.enquiryId,
      });
    },
    handleAllocationCustomer() {
      this.customerIds = this.$refs.GeneralTable.tableSelectionList.map(
        (val) => val.id
      );
      if (this.customerIds.length) {
        this.isAllocationCustomer = true;
      } else {
        this.$message.error("请选择保单");
      }
    },
    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (decimal == 2) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      } else {
        // 只能输入4个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3");
      }

      //如果有小数点，不能为类似 1.10的金额
      // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      // //如果有小数点，不能为类似 0.00的金额
      // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (e.indexOf(".") <= 0 && e != "") {
        tiem[key] = parseFloat(e);
      }
      if (e > max) {
        const aa = e + "";
        tiem[key] = parseFloat(aa.slice(0, e.length - 1));
      }
    },
    handleRevocation(item) {
      this.$confirm("是否确认撤回？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          policyProcessRevoke({
            processType: 2,
            bussId: item.id,
            processId: item.processId,
          }).then((res) => {
            this.changeList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .status {
    position: relative;
    color: #cccccc;
    padding-left: 18px;
    font-size: 14px;
    display: inline-block;
  }
  .status::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #cccccc;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .status1 {
    color: #fca118;
  }
  .status1::after {
    background-color: #fca118;
  }
  .status2 {
    color: #0080ff;
  }
  .status2::after {
    background-color: #0080ff;
  }
  .approval-status {
    position: relative;
    padding-left: 18px;
    font-size: 14px;
  }
  .approval-status::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .approval-status3 {
    color: #00bc0d;
  }
  .approval-status3::after {
    background-color: #00bc0d;
  }
  .approval-status2 {
    color: #0080ff;
  }
  .approval-status2::after {
    background-color: #0080ff;
  }
  .approval-status4 {
    color: #cccccc;
  }
  .approval-status4::after {
    background-color: #cccccc;
  }
  .approval-status1 {
    color: #c94242;
  }
  .approval-status1::after {
    background-color: #c94242;
  }
  .approval-status5 {
    color: #00bc0d;
  }
  .approval-status5::after {
    background-color: #00bc0d;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
.reset-password {
  display: flex;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 12px;
  font-size: 14px;
}
::v-deep .el-dropdown,
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>
